/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Rule from "@domain/entities/rule-entity";
import { injectable } from "inversify";
import { IApiRule, IApiRuleList, IApiRuleSubjectList } from "../interfaces/rule-interface";

@injectable()
export default abstract class RuleRepository {
  async getRuleListPagination (query?: Rule): Promise<IApiRuleList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async getRuleList (query?: Rule): Promise<IApiRuleList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async createRule (data: Rule): Promise<IApiRule> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async updateRule (id: string, body: any): Promise<IApiRule> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async removeRule (query: any): Promise<IApiRule> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getRuleSubject (): Promise<IApiRuleSubjectList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
