/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Account from "@domain/entities/account-entity";
import { injectable } from "inversify";
import {
  IApiAccount,
  IApiAccountList,
  IApiAccountLogin
} from "../interfaces/account-interface";

@injectable()
export default abstract class AccountRepository {
  async getAccountListPagination(query: any): Promise<IApiAccountList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async getAccountList(query: any): Promise<IApiAccountList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async createAccount(data: Account): Promise<IApiAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async updateAccount(id: string, body: any): Promise<IApiAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async removeAccount(query: any): Promise<IApiAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async updateDeviceInfo(query: any): Promise<IApiAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async login(body: any): Promise<IApiAccountLogin> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async loginFacebook(body: any): Promise<IApiAccountLogin> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async logout(id: string): Promise<IApiAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async changePassword(id: string, body: any): Promise<IApiAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async forgotPassword(body: any): Promise<IApiAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async resetPassword(body: any): Promise<IApiAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async uploadAvatar(body: any): Promise<IApiAccount> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
