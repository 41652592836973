import ApiService from "@common/api";
import { IApiRoleList } from "@domain/interfaces/role-interface";
import RoleRepository from "@domain/repositories/role-repository";

export default class RoleApiRepository extends RoleRepository {
  private apiService = new ApiService(gConfig.api.host, {
    product: gConfig.api.product,
    token: gConfig.api.token,
    authorization: localStorage.getItem("dataAccess") ? JSON.parse(localStorage.getItem("dataAccess") || "{token: ''}").token : "",
    deviceInfo: {},
  });

  async getRoleListPagination (query: any): Promise<IApiRoleList> {
    return await this.apiService.get("/roles/list", query);
  }

  async getRoleList (query: any) {
    return await this.apiService.get("/roles", query);
  }

  async createRole (body: any) {
    return await this.apiService.post("/roles", body);
  }

  async updateRole (id: string, body: any) {
    return await this.apiService.put(`/roles/${id}`, body);
  }

  async removeRole (id: string) {
    return await this.apiService.remove(`/roles/${id}`);
  }
}
