/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Role from "@domain/entities/role-entity";
import { injectable } from "inversify";
import { IApiRole, IApiRoleList } from "../interfaces/role-interface";

@injectable()
export default abstract class RoleRepository {
  async getRoleListPagination (query: any): Promise<IApiRoleList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async getRoleList (query: any): Promise<IApiRoleList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async createRole (data: Role): Promise<IApiRole> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async updateRole (id: string, body: any): Promise<IApiRole> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async removeRole (query: any): Promise<IApiRole> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
