import ApiService from "@common/api";
import { IApiRuleList, IApiRuleSubjectList } from "@domain/interfaces/rule-interface";
import RuleRepository from "@domain/repositories/rule-repository";

export default class RuleApiRepository extends RuleRepository {
  private apiService = new ApiService(gConfig.api.host, {
    product: gConfig.api.product,
    token: gConfig.api.token,
    authorization: localStorage.getItem("dataAccess") ? JSON.parse(localStorage.getItem("dataAccess") || "{token: ''}").token : "",
    deviceInfo: {},
  });

  async getRuleListPagination (query: any): Promise<IApiRuleList> {
    return await this.apiService.get("/rules", query);
  }

  async getRuleList (query: any) {
    return await this.apiService.get("/rules", query);
  }

  async createRule (body: any) {
    return await this.apiService.post("/rules", body);
  }

  async updateRule (id: string, body: any) {
    return await this.apiService.put(`/rules/${id}`, body);
  }

  async removeRule (id: string) {
    return await this.apiService.remove(`/rules/${id}`);
  }

  async getRuleSubject (): Promise<IApiRuleSubjectList> {
    return await this.apiService.get("/rules/subject", {});
  }
}
