export default class Account {
  id: string;
  username: string;
  name: string;
  email: string;
  phone: string;
  status: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  oauth: any;
  avatar: string;

  constructor(data?: any) {
    this.username = data?.username || "";
    this.id = data?.id || "";
    this.name = data?.name || "";
    this.email = data?.email || "";
    this.phone = data?.phone || "";
    this.status = data?.status || "";
    this.createdBy = data?.createdBy || "";
    this.updatedBy = data?.updatedBy || "";
    this.createdAt = data?.createdAt || "";
    this.updatedAt = data?.updatedAt || "";
    this.oauth = data?.oauth || {};
    this.avatar = data?.avatar || "";
  }
}
