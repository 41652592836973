import { repoContainer } from "@/config/inversify.config";
// import crypto from "@/core/utils/crypto";
import AccountRepository from "../../repositories/account-repository";

const accountRepository = repoContainer.get<AccountRepository>(AccountRepository);

export default async function loginUseCase (values: { username: string; password: string }) {
  return await accountRepository.login({
    username: values.username,
    password: values.password,
  });
}
