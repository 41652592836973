/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
// import Page from "@domain/entities/page-entity";
import { IApiResponse } from "@common/api";
import { injectable } from "inversify";
import {
  IApiPageActiveBody,
  IPageActiveResponse,
  IPageResponse,
  IPageByIdResponse
} from "../interfaces/page-interface";

@injectable()
export default abstract class PageRepository {
  async getPageList(userId: string): Promise<IPageResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async activePage(data: IApiPageActiveBody): Promise<IApiResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async updatePage(pageId: string, data: any): Promise<IApiResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getActivePageList(
    userId: string,
    query: any
  ): Promise<IPageActiveResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getPageById(id: string): Promise<IPageByIdResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
