import changePasswordUseCase from "@domain/use-cases/account/change-password";
import forgotPasswordUseCase from "@domain/use-cases/account/forgot-password";
import loginUseCase from "@domain/use-cases/account/login";
import loginFacebookUseCase from "@domain/use-cases/account/login-facebook";
// import logoutUseCase from "@domain/use-cases/account/logout";
import resetPasswordUseCase from "@domain/use-cases/account/reset-password";
import updateDeviceInfoUseCase from "@domain/use-cases/account/update-device-info";

import createAccountUseCase from "@domain/use-cases/account/create-account";
import getAccountListUseCase from "@domain/use-cases/account/get-account-list";
import removeAccountUseCase from "@domain/use-cases/account/remove-account";
import updateAccountUseCase from "@domain/use-cases/account/update-account";

import ErrorCode from "@common/kernel/error-code";
import Account from "@domain/entities/account-entity";
import {
  AccountFormState,
  IChangePasswordBody,
  ILoginBody,
  ILoginFacebookBody
} from "@domain/interfaces/account-interface";

import { defineStore } from "pinia";
import { useAlertStore } from "./alert-store";

export const useAccountStore = defineStore("account", {
  state: () => ({
    loading: false,
    loginUser: new Account(),
    token: "",
    table: {
      fetching: false,
      data: [] as Array<Account>,
      total: 0,
      limit: 10,
    },
  }),

  getters: {
    getLoginUser: (state) => state.loginUser,
    isLogin: (state) => !!state.loginUser.id,
  },
  persist: {
    key: "dataAccess",
    paths: ["loginUser", "token"],
  },
  actions: {
    updateDeviceInfo() {
      updateDeviceInfoUseCase(this.loginUser.id);
    },
    async login(data: ILoginBody) {
      const result = await loginUseCase(data);
      const isSuccess = result.code === ErrorCode.SUCCESS.code;
      const alert = useAlertStore();

      if (isSuccess) {
        this.loginUser = result.data.user;
        this.token = result.data.token;

        alert.success(`Welcome back ${this.loginUser.name}!`);
      } else {
        alert.error(result.message);
      }

      return isSuccess;
    },
    async loginFacebook(data: ILoginFacebookBody) {
      const result = await loginFacebookUseCase(data);
      const isSuccess = result.code === ErrorCode.SUCCESS.code;
      const alert = useAlertStore();

      if (isSuccess) {
        this.loginUser = result.data.user;
        this.token = result.data.token;

        alert.success(`Welcome back ${this.loginUser.name}!`);
      } else {
        alert.error(result.message);
      }

      return isSuccess;
    },
    async logout() {
      this.loginUser = new Account();
      this.token = "";
      useAlertStore().success("Đăng xuất thành công");
    },
    async changePassword(data: IChangePasswordBody) {
      this.loading = true;

      const result = await changePasswordUseCase(this.loginUser.id, data);
      this.loading = false;

      useAlertStore().success(result.message);
    },
    async resetPassword() {
      this.loading = true;
      const result = await resetPasswordUseCase({
        id: this.loginUser.id,
        password: "",
      });

      useAlertStore().success(result.message);
      this.loading = false;
    },
    async forgotPassword() {
      this.loading = true;

      await forgotPasswordUseCase(this.loginUser.email);
      this.loading = false;
    },
    // ========= table =============
    async getAccountList(query?: Account) {
      this.table.fetching = true;

      const result = await getAccountListUseCase(query);
      this.table.data = result.data.items?.map((item, index) => ({
        ...item,
        key: index,
      }));
      this.table.fetching = false;
    },

    async createAccount(data: AccountFormState) {
      const result = await createAccountUseCase(new Account(data));
      const isSuccess = result.code === ErrorCode.SUCCESS.code;
      const alert = useAlertStore();

      if (!isSuccess) return alert.error(result.message);

      alert.success(result.message);
      this.table.data.push(new Account(result.data));

      return isSuccess;
    },
    async updateAccount(data: AccountFormState) {
      const result = await updateAccountUseCase(data.id, new Account(data));
      const isSuccess = result.code === ErrorCode.SUCCESS.code;
      const alert = useAlertStore();

      if (!isSuccess) return alert.error(result.message);

      alert.success(result.message);
      const index = this.table.data.findIndex((item) => item.id === data.id);

      this.table.data.splice(index, 1, new Account(result.data));
    },

    async removeAccount(id: string) {
      const result = await removeAccountUseCase(id);
      const isSuccess = result.code === ErrorCode.SUCCESS.code;
      const alert = useAlertStore();

      if (!isSuccess) return alert.error(result.message);

      alert.success(result.message);
      this.table.data = this.table.data.filter((item) => item.id !== id);

      return isSuccess;
    },
  },
});
