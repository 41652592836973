import { repoContainer } from "@/config/inversify.config";
import crypto from "@common/utils/crypto";
import AccountRepository from "../../repositories/account-repository";
const accountRepository = repoContainer.get<AccountRepository>(AccountRepository);

export default async function resetPasswordUseCase (values: { id: string; password: string }) {
  return await accountRepository.resetPassword({
    id: values.id,
    password: crypto.sha256(values.password),
  });
}
