import ApiService from "@common/api";
import MessageRepository from "@domain/repositories/message-repository";

export default class MessageApiRepository extends MessageRepository {
  private apiService = new ApiService(gConfig.api.host, {
    product: gConfig.api.product,
    token: gConfig.api.token,
    accessToken: localStorage.getItem("dataAccess")
      ? JSON.parse(localStorage.getItem("dataAccess") || "{token: ''}").token
      : "",
    deviceInfo: {},
  });

  async sendMessage(pageId: string, body: any) {
    return await this.apiService.post(
      `/facebook/pages/${pageId}/sendMessage`,
      body
    );
  }
  async imageUpload(body: any) {
    return await this.apiService.post("/uploads", body);
  }
}
