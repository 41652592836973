import { routesName } from "@common/route";
import { useAccountStore } from "@presentation/store/account-store";
import { paramCase, pascalCase } from "change-case-all";
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: routesName.login,
    meta: { authRequired: false },
    component: () => import("@interfaces/views/LoginView.vue"),
    beforeEnter: (to, from, next) => {
      const userStore = useAccountStore();

      if (!userStore.isLogin) return next();

      return next("home");
    },
  },
  {
    path: "/",
    name: routesName.root,
    component: () => import("@interfaces/views/AdminView.vue"),
    beforeEnter: (to, from, next) => {
      const userStore = useAccountStore();
      if (!userStore.isLogin) return next(routesName.login);
      return next();
    },
    redirect: () => {
      return { name: "home" };
    },
    children: routesName.admin.map((item) => {
      return {
        meta: {
          title: item.name,
        },
        name: item.name,
        path: `/${paramCase(item.name)}`,
        component: () =>
          import(`@interfaces/views/admin/${pascalCase(item.name)}View.vue`),
      };
    }) as [],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
