import "@config/index";
import "ant-design-vue/dist/reset.css";
// import "@ant-design-vue/pro-layout/dist/style.css";
import i18n from "@infrastructure/i18n";
import router from "@infrastructure/router";
import Antd from "ant-design-vue";
import { createPinia } from "pinia";
import { createApp } from "vue";
import moment from "moment";
import App from "./App.vue";
import { Icon } from "@common/utils/icon";

import ProLayout, { PageContainer } from "@ant-design-vue/pro-layout";
import { PiniaLogger } from "pinia-logger";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
moment.locale("vi");

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate).use(
  PiniaLogger({
    expanded: true,
    disabled: process.env.mode === "production",
    // use a filter to only log certain actions
    // filter: ({ name }) => name !== "incrementCounter",
    // alternatively, you can specify the actions you want to log
    // if undefined, all actions will be logged
    // actions: ["clear"],
  })
);

const app = createApp(App);
// app.config.globalProperties.moment = moment;

app
  .use(Antd)
  .use(ProLayout)
  .use(PageContainer)
  .use(router)
  .use(i18n)
  .use(pinia)
  .component("icon", Icon)

  .mount("#app");
