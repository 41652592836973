import ErrorCode, {
  API_APP_ERROR,
  API_SYS_ERROR
} from "@common/kernel/error-code";
import { getFirebaseToken } from "@common/utils/firebase-message";
import { message } from "ant-design-vue";
import axios from "axios";
import fileDownload from "js-file-download";

export interface IApiResponse {
  status: number;
  code: number;
  message: string;
  data: any;
}

export default class ApiService {
  host: string;
  headers: object;

  constructor(host: string, headers: object) {
    this.host = host;
    this.headers = headers;
  }

  private sessionTimeout = (code: number) => {
    if (code === ErrorCode.SESSION_TIMEOUT.code)
      message.error(ErrorCode.SESSION_TIMEOUT.message);
  };

  async get(path: string, query: object) {
    try {
      const headers = {
        ...this.headers,
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
      };
      const url = `${this.host}${path}`;
      const response = await axios.get(url, {
        params: query,
        headers,
      });

      if (!response || !response.data) return API_SYS_ERROR as IApiResponse;

      const result = response.data as IApiResponse;
      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      return API_APP_ERROR as IApiResponse;
    }
  }

  async post(path: string, body: object, query?: object) {
    try {
      const headers = {
        ...this.headers,
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
      };
      const url = `${this.host}${path}`;
      const response = await axios.post(url, body, {
        headers,
        params: query,
      });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;
      this.sessionTimeout(result.code);

      return result;
    } catch (error: any) {
      return error.response.data;
    }
  }

  async put(path: string, body: object, query?: object) {
    try {
      const headers = {
        ...this.headers,
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
      };
      const url = `${this.host}${path}`;
      const response = await axios.put(url, body, {
        headers,
        params: query,
      });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;
      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      return API_APP_ERROR;
    }
  }

  async remove(path: string) {
    try {
      const headers = {
        ...this.headers,
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
      };
      const url = `${this.host}${path}`;
      const response = await axios.delete(url, { headers });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;
      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      return API_APP_ERROR;
    }
  }

  async save(path: string, method = "POST", fileName: string) {
    const url = `${this.host}${path}`;
    const headers = { ...this.headers, authorization: "" };

    return await axios({
      url,
      method,
      responseType: "blob", // Important
      headers,
    })
      .then((response) => {
        fileDownload(response.data, fileName);

        return { isSuccess: true };
      })
      .catch(() => {
        return { isSuccess: false };
      });
  }

  async upload(path: string, data: unknown) {
    try {
      const headers = { ...this.headers, authorization: "" };
      const url = `${this.host}${path}`;
      const response = await axios.post(url, data, { headers });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;
      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      return API_APP_ERROR;
    }
  }
}
