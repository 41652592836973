import { Container } from "inversify";
import "reflect-metadata";

import AccountRepository from "@domain/repositories/account-repository";
import AccountRepositoryApi from "@infrastructure/repositories/api/account-api-repo";
import AccountRepositoryMockup from "@infrastructure/repositories/mockup/account-mockup-repo";

import RoleRepository from "@domain/repositories/role-repository";
import RoleRepositoryApi from "@infrastructure/repositories/api/role-api-repo";
import RoleRepositoryMockup from "@infrastructure/repositories/mockup/role-mockup-repo";

import RuleRepository from "@domain/repositories/rule-repository";
import RuleRepositoryApi from "@infrastructure/repositories/api/rule-api-repository";
import RuleRepositoryMockup from "@infrastructure/repositories/mockup/rule-mockup-repository";

import MessageRepository from "@domain/repositories/message-repository";
import MessageRepositoryApi from "@infrastructure/repositories/api/message-api-repo";

import PageRepository from "@domain/repositories/page-repository";
import PageRepositoryApi from "@infrastructure/repositories/api/page-api-repo";

const repoContainer = new Container();

if (process.env.VUE_APP_REPOSITORY === "api") {
  repoContainer.bind<RuleRepository>(RuleRepository).to(RuleRepositoryApi);
  repoContainer
    .bind<AccountRepository>(AccountRepository)
    .to(AccountRepositoryApi);
  repoContainer.bind<RoleRepository>(RoleRepository).to(RoleRepositoryApi);
  repoContainer
    .bind<MessageRepository>(MessageRepository)
    .to(MessageRepositoryApi);
  repoContainer.bind<PageRepository>(PageRepository).to(PageRepositoryApi);
} else {
  repoContainer.bind<RuleRepository>(RuleRepository).to(RuleRepositoryMockup);
  repoContainer
    .bind<AccountRepository>(AccountRepository)
    .to(AccountRepositoryMockup);
  repoContainer.bind<RoleRepository>(RoleRepository).to(RoleRepositoryMockup);
}

export { repoContainer };
