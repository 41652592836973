import { defineStore } from "pinia";
import color from "@/common/color";
export const useConfigStore = defineStore("config", {
  state: () => ({
    theme: { token: { colorPrimary: color.primary } },
  }),
  persist: true,
  actions: {
    // setColorPrimary (color: string) {
    //   this.theme = { token: { colorPrimary: color } };
    // },
  },
});
