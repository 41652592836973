import { repoContainer } from "@/config/inversify.config";
import crypto from "@common/utils/crypto";
import AccountRepository from "../../repositories/account-repository";

const accountRepository = repoContainer.get<AccountRepository>(AccountRepository);

export default async function changePasswordUseCase (id: string, values: { oldPassword: string; newPassword: string }) {
  return await accountRepository.changePassword(id, {
    oldPassword: crypto.sha256(values.oldPassword),
    newPassword: crypto.sha256(values.newPassword),
  });
}
