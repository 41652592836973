import ApiService from "@common/api";
import PageRepository from "@domain/repositories/page-repository";

export default class PageApiRepository extends PageRepository {
  private apiService = new ApiService(gConfig.api.host, {
    product: gConfig.api.product,
    token: gConfig.api.token,
    accessToken: localStorage.getItem("dataAccess")
      ? JSON.parse(localStorage.getItem("dataAccess") || "{token: ''}").token
      : "",
    deviceInfo: {},
  });

  async getPageList(userId: string) {
    return await this.apiService.get(`/facebook/users/${userId}/pages`, {});
  }

  async activePage(data: any) {
    return await this.apiService.post("/pages/active", data);
  }

  async updatePage(id: string, data: any) {
    return await this.apiService.put(`/pages/${id}`, data);
  }

  async getActivePageList(userId: string, query: any) {
    return await this.apiService.get(`/users/${userId}/pages`, query);
  }

  async getPageById(id: string) {
    return await this.apiService.get(`/pages/${id}`, {});
  }
}
