import { repoContainer } from "@/config/inversify.config";
// import crypto from "@/core/utils/crypto";
import AccountRepository from "../../repositories/account-repository";
import { ILoginFacebookBody } from "@domain/interfaces/account-interface";
const accountRepository =
  repoContainer.get<AccountRepository>(AccountRepository);

export default async function loginFacebookUseCase(data: ILoginFacebookBody) {
  return await accountRepository.loginFacebook(data);
}
