import ApiService from "@common/api";
import { IApiAccountList } from "@domain/interfaces/account-interface";
import AccountRepository from "@domain/repositories/account-repository";

export default class AccountApiRepository extends AccountRepository {
  private apiService = new ApiService(gConfig.api.host, {
    product: gConfig.api.product,
    token: gConfig.api.token,
    accessToken: localStorage.getItem("dataAccess")
      ? JSON.parse(localStorage.getItem("dataAccess") || "{token: ''}").token
      : "",
    deviceInfo: {},
  });

  async getAccountListPagination(query: any): Promise<IApiAccountList> {
    return await this.apiService.get("/account/list", query);
  }

  async getAccountList(query: any) {
    return await this.apiService.get("/account", query);
  }

  async createAccount(body: any) {
    return await this.apiService.post("/account", body);
  }

  async updateAccount(id: string, body: any) {
    return await this.apiService.put(`/account/${id}`, body);
  }

  async updateDeviceInfo(id: string) {
    return await this.apiService.put(`/account/${id}/device-info`, {});
  }

  async removeAccount(id: string) {
    return await this.apiService.remove(`/account/${id}`);
  }

  async login(body: any) {
    return await this.apiService.post("/users/login", body);
  }

  async loginFacebook(body: any) {
    return await this.apiService.post("/users/login/facebook", body);
  }

  async logout(id: string) {
    return await this.apiService.post("/account/logout", { id });
  }

  async changePassword(id: string, body: any) {
    return await this.apiService.put(`/account/${id}/change-password`, body);
  }

  async forgotPassword(body: any) {
    return await this.apiService.post("/account/forgot-password", body);
  }

  async resetPassword(body: any) {
    return await this.apiService.post("/account/reset-password", body);
  }

  async uploadAvatar(body: any) {
    return await this.apiService.upload("/account/upload/avatar", body);
  }
}
