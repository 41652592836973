export const routesName = {
  login: "login",
  root: "root",
  admin: [
    {
      name: "home",
      icon: "DashboardOutlined",
    },
    {
      name: "chat",
      hidden: true,
      icon: "ChatOutlined",
    },
    {
      name: "config",
      icon: "SettingOutlined",
    },
    // account: "account",
    // role: "role",
    // rule: "rule",
  ],
};
