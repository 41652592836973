/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import { injectable } from "inversify";
import {
  IMesageResponse,
  IImageUploadResponse
} from "../interfaces/message-interface";

@injectable()
export default abstract class RuleRepository {
  async sendMessage(pageId: string, data: any): Promise<IMesageResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async imageUpload(data: any): Promise<IImageUploadResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
